<template>
  <div class="relative">
    <div class="filter filter-map fixed">
      <div class="filter__item" @click="findRealEstate" >
          <span class="bongah-filter">
<svg xmlns="http://www.w3.org/2000/svg" width="29.588" height="19.353" viewBox="0 0 29.588 19.353">
  <g id="Group_12961" data-name="Group 12961" transform="translate(-33.006 -164.331)">
    <path id="ic_location_city_24px" d="M15,11V5L12,2,9,5V7l.147,14H21V11Zm-2,8H11V17h2Zm0-4H11V13h2Zm0-4H11V9h2Zm0-4H11V5h2Zm6,12H17V17h2Zm0-4H17V13h2Z" transform="translate(24.006 162.331)" fill="#fff"/>
    <path id="Union_2" data-name="Union 2" d="M10.686,10.719l3.147,3.156ZM0,6.188a6.168,6.168,0,1,1,6.168,6.188A6.178,6.178,0,0,1,0,6.188Z" transform="translate(48.407 169.455)" fill="none" stroke="#fff" stroke-width="1"/>
  </g>
</svg>
          </span>
        <p>برای جستجوی بنگاه خود انتخاب کنید</p>
      </div>
      <div class="filter__item" @click="()=> this.$router.push({name : 'NotificationsCustomer'})">
           <span class="bongah-filter">
          <svg id="Group_12763" data-name="Group 12763" xmlns="http://www.w3.org/2000/svg" width="20.002" height="25.256" viewBox="0 0 20.002 25.256">
  <path id="ic_notifications_24px" d="M21.5,19.377V13.126c0-3.838-2.05-7.051-5.626-7.9v-.85a1.875,1.875,0,0,0-3.75,0v.85c-3.588.85-5.626,4.05-5.626,7.9v6.251L4,21.877v1.25H24v-1.25Z" transform="translate(-4 -2.5)" fill="#fff"/>
  <path id="Path_17926" data-name="Path 17926" d="M5,1.913a2.5,2.5,0,0,1-5,0C0,.534,5,.534,5,1.913Z" transform="translate(7.501 20.847)" fill="#fff"/>
</svg>
        </span>
        <span class="notification__count">{{ notifCount }}</span>
        <p>اعلانات شما</p>
      </div>
      <div class="filter__item" @click="handleCityFilter">
          <span class="filter__item--city">
<svg xmlns="http://www.w3.org/2000/svg" width="49.686" height="49.729" viewBox="0 0 49.686 49.729">
  <g id="Group_12935" data-name="Group 12935" transform="translate(-102.365 1.564)">
    <path id="Rectangle_12123" data-name="Rectangle 12123" d="M0,0H30A15,15,0,0,1,45,15V30A15,15,0,0,1,30,45H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(105)" fill="#ff7400"/>
    <path id="Union_1" data-name="Union 1" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(117.865 13.936)" fill="none" stroke="#fff" stroke-width="1"/>
    <path id="Union_9" data-name="Union 9" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(117.865 13.936)" fill="none" stroke="#fff" stroke-width="1"/>
  </g>
</svg>
          </span>
        <p>{{searchText ? searchText : `برای جستجوی شهر خود انتخاب کنید`}}</p>
      </div>
      <div class="filter__item" @click="isFiltersOpen = true">
          <span class="bongah-filter"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="options" transform="translate(20 0) rotate(90)">
    <rect id="Rectangle_524" data-name="Rectangle 524" width="20" height="20" transform="translate(0 0)" fill="#fff" opacity="0"/>
    <path id="Path_1287" data-name="Path 1287" d="M3.222,9.869V.81A.81.81,0,1,0,1.6.81V9.869a2.431,2.431,0,0,0,0,4.57V15.4a.81.81,0,1,0,1.621,0V14.44a2.431,2.431,0,0,0,0-4.57Z" transform="translate(2.848 1.958)" fill="#fff"/>
    <path id="Path_1288" data-name="Path 1288" d="M4.843,8.913A2.431,2.431,0,0,0,3.222,6.628V.81A.81.81,0,0,0,1.6.81V6.628a2.431,2.431,0,0,0,0,4.57v4.2a.81.81,0,1,0,1.621,0V11.2A2.431,2.431,0,0,0,4.843,8.913Z" transform="translate(12.571 1.958)" fill="#fff"/>
    <path id="Path_1289" data-name="Path 1289" d="M4.862,2.438A2.431,2.431,0,1,0,1.621,4.723V15.4a.81.81,0,1,0,1.621,0V4.723A2.431,2.431,0,0,0,4.862,2.438Z" transform="translate(7.69 1.951)" fill="#fff"/>
  </g>
</svg>
          </span>
        <p>
          اعمال فیلتر
          <span v-if="isFilter" @click.stop="clearFilter" class="delete-filter">حذف فیلتر</span>
        </p>

      </div>
    </div>
    <div id="map">
      <mapir
          :apiKey="token"
          :mapStyle="options.style"
          :center="coordinates"
          :zoom="zoom"
          @touchstart="hideBox"
          @dragstart="hideBox"
      >
        <mapGeolocateControl @geolocate="currentLocation" position="bottom-right" />
        <mapNavigationControl position="bottom-right" />
        <mapMarker
            v-for="(estate , i) in filterData"
            :key="`A-${i}`"
            :coordinates="[estate.geoposition.longitude ,estate.geoposition.latitude]"
            color="blue"
            :draggable="false"
            @click="handleShowEstate(estate)"
        >
          <template slot="marker">
            <img class="w-8" v-if="estate.advertisement_type ===  'اجاره' ||estate.advertisement_type ===  'رهن و اجاره' " src="../../assets/logo.png" alt="">
            <img class="w-8" v-else src="../../assets/images/icons/sale.png" alt="">
          </template>
        </mapMarker>
        <!--FOR REALESatte-->
        <mapMarker
            v-for="(realEstate , i) in filterRealestateData"
            :key="`B-${i}`"
            :coordinates="[realEstate.geoposition.longitude ,realEstate.geoposition.latitude]"
            color="blue"
            :draggable="false"
            @click="handleShowRealEstate(realEstate)"
        >
          <template slot="marker">
            <img class="w-8 rounded-full border-realestate" :src="baseURL + realEstate.logo" alt="">
          </template>
        </mapMarker>
      </mapir>
    </div>
    <FiltersMap
        v-if="isFiltersOpen"
        :isFiltersOpen="isFiltersOpen"
        @close-filter-box="isFiltersOpen = false"
    />
    <div class="product_wrapper map" v-if="isShowEstate">
      <router-link
          :to="{name : 'EstateCustomer' , params :{ id : estate.id}}"
          class="product z-0"
          id="estate"
      >
        <div class="custom-tour z-50 left-5"><span>برای بازدید از بنگاه اینجا کلیک کنید</span></div>

        <div class="image-section">
          <img class="image-product" :src="baseURL + estate.thumbnail.link" alt="">

          <div class="top">
            <span>{{ estate.advertisement_type }}</span>
            <router-link :to="{name : 'RealEstatePageCustomer' , params : {id : estate.realestate.id }}">
              <img :src="baseURL + [estate.realestate !== null ? estate.realestate.logo : ' ']  "
                   :alt="[estate.realestate !== null ? estate.realestate.name : ''] ">
            </router-link>
          </div>
          <div class="bottom ">
            <a :href="estate.video_link" class="relative">مشاهده ویدئو
              <svg xmlns="http://www.w3.org/2000/svg" width="13.634" height="13.634" viewBox="0 0 13.634 13.634">
                <g id="Group_12813" data-name="Group 12813" transform="translate(-62.183 -563.183)">
                  <g id="instagram" transform="translate(62.183 563.183)">
                    <path id="Path_17917" data-name="Path 17917"
                          d="M9.942,0H3.69A3.7,3.7,0,0,0,0,3.691V9.944A3.7,3.7,0,0,0,3.69,13.634H9.943a3.7,3.7,0,0,0,3.691-3.691V3.691A3.7,3.7,0,0,0,9.942,0Zm2.891,9.944a2.9,2.9,0,0,1-2.891,2.891H3.69A2.9,2.9,0,0,1,.8,9.944V3.691A2.9,2.9,0,0,1,3.69.8H9.943a2.9,2.9,0,0,1,2.891,2.891Zm0,0"
                          transform="translate(0.001 0)" fill="#ffffffb5"/>
                    <path id="Path_17918" data-name="Path 17918"
                          d="M119.732,116a3.728,3.728,0,1,0,3.728,3.728A3.732,3.732,0,0,0,119.732,116Zm0,6.657a2.929,2.929,0,1,1,2.929-2.929A2.932,2.932,0,0,1,119.732,122.661Zm0,0"
                          transform="translate(-112.915 -112.915)" fill="#ffffffb5"/>
                    <path id="Path_17919" data-name="Path 17919"
                          d="M359.078,66.285a1.1,1.1,0,1,0,1.1,1.1A1.1,1.1,0,0,0,359.078,66.285Zm0,1.4a.3.3,0,1,1,.3-.3A.3.3,0,0,1,359.078,67.689Zm0,0"
                          transform="translate(-348.444 -64.52)" fill="#ffffffb5"/>
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
        <div class="detail-section">
          <div class="top">
            <p>{{ estate.title }}</p>
            <p v-if="estate.advertisement_type_en === 'rent' || estate.advertisement_type_en === 'mortgageRent'" class="text-white text-left">{{( '' +estate.options.downPayment).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}<span>تومان</span></p>
 <p v-else class="text-white text-left ">{{( '' +estate.options.price).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}  <span>تومان</span> </p>


          </div>
          <div class="bottom">
            <div>
              <svg id="Group_12590" data-name="Group 12590" xmlns="http://www.w3.org/2000/svg" width="17.521"
                   height="11.136" viewBox="0 0 17.521 11.136">
                <g id="bed">
                  <path id="Path_9" data-name="Path 9"
                        d="M57.482,91.355A1.66,1.66,0,0,0,55.824,89.7H52.1a1.658,1.658,0,0,0-1.385.747,1.658,1.658,0,0,0-1.385-.747H45.609a1.66,1.66,0,0,0-1.658,1.658v.076h-.415V89.814a1.822,1.822,0,0,1,1.82-1.82H56.077a1.822,1.822,0,0,1,1.82,1.82v1.617h-.415v-.076Z"
                        transform="translate(-41.955 -87.994)" fill="#ff7400"/>
                  <path id="Path_10" data-name="Path 10"
                        d="M.7,204.076H16.824a.7.7,0,0,1,.7.7v4.046a.7.7,0,0,1-.7.7h-.29l.343.968a.387.387,0,1,1-.73.259l-.434-1.226H1.809l-.434,1.226a.387.387,0,0,1-.73-.259l.343-.968H.7a.7.7,0,0,1-.7-.7v-4.046A.7.7,0,0,1,.7,204.076Z"
                        transform="translate(0 -199.864)" fill="#ff7400"/>
                  <path id="Path_11" data-name="Path 11"
                        d="M259.87,156.262a.885.885,0,0,1,.884.884v.076h-5.491v-.076a.885.885,0,0,1,.884-.884Z"
                        transform="translate(-252.494 -153.785)" fill="#ff7400"/>
                  <path id="Path_12" data-name="Path 12"
                        d="M80.932,156.262a.885.885,0,0,1,.884.884v.076H76.325v-.076a.885.885,0,0,1,.884-.884Z"
                        transform="translate(-67.064 -153.785)" fill="#ff7400"/>
                </g>
              </svg>
              <span>{{ estate.options.number_of_bedrooms }} خوابه</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.776" height="12.398" viewBox="0 0 13.776 12.398">
                <path id="ic_business_24px"
                      d="M8.888,5.755V3h6.888V15.4H2V5.755ZM13.02,14.02H14.4V12.643H13.02Zm0-2.755H14.4V9.888H13.02Zm0-2.755H14.4V7.133H13.02Zm0-2.755H14.4V4.378H13.02ZM10.265,14.02h1.378V12.643H10.265Zm0-2.755h1.378V9.888H10.265Zm0-2.755h1.378V7.133H10.265Zm0-2.755h1.378V4.378H10.265ZM3.378,14.02h5.51V12.643H7.51V11.265H8.888V9.888H7.51V8.51H8.888V7.133H3.378ZM4.755,8.51H6.133V9.888H4.755Zm0,2.755H6.133v1.378H4.755Z"
                      transform="translate(-2 -3)" fill="#ff7400"/>
              </svg>
              <span>  {{ estate.options.age }} ساله</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="12.398" height="12.398" viewBox="0 0 12.398 12.398">
                <path id="ic_format_shapes_24px"
                      d="M13.4,4.381V1H10.017V2.127H4.381V1H1V4.381H2.127v5.635H1V13.4H4.381V12.271h5.635V13.4H13.4V10.017H12.271V4.381ZM2.127,2.127H3.254V3.254H2.127ZM3.254,12.271H2.127V11.144H3.254Zm6.763-1.127H4.381V10.017H3.254V4.381H4.381V3.254h5.635V4.381h1.127v5.635H10.017Zm2.254,1.127H11.144V11.144h1.127ZM11.144,3.254V2.127h1.127V3.254Z"
                      transform="translate(-1 -1)" fill="#ff7400"/>
              </svg>
              <span>{{ estate.options.area }}متر</span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div
        v-if="isShowRealEstate"
        class="bongah-box z-0 product_wrapper map">
      <router-link
          tag="div"
          :to="{name : 'RealEstatePageCustomer' , params :{ id : realEstate.id}}"
          class="image-section">

        <img class="image-product" v-if="realEstate.media.length >= 1" :src="baseURL + realEstate.media[realEstate.media.length - 1].file" alt="">
        <img class="image-product py-4" v-else src="../../assets/images/digitalmarket.png" alt="">

      </router-link>
      <div class="detail-section">
        <router-link tag="div" :to="{name : 'RealEstatePageCustomer' , params :{ id : realEstate.id}}" class="logo">
          <img :src="baseURL + realEstate.logo" alt="">
        </router-link>
        <p> نام بنگاه : <span>{{realEstate.name}}</span> </p>
        <div class="flex justify-between gap-2">
          <a target="_blank" :href="`tel:${realEstate.telephone}`" class="bongah-filter z-50 relative"><svg xmlns="http://www.w3.org/2000/svg" width="20.879" height="20.877" viewBox="0 0 20.879 20.877">
            <path id="Icon_ionic-ios-call" data-name="Icon ionic-ios-call" d="M24.766,20.85A17.537,17.537,0,0,0,21.1,18.4c-1.1-.527-1.5-.516-2.277.043-.647.467-1.065.9-1.81.739a10.8,10.8,0,0,1-3.636-2.69,10.72,10.72,0,0,1-2.69-3.636c-.158-.75.277-1.163.739-1.81.56-.777.576-1.179.043-2.277A17.19,17.19,0,0,0,9.02,5.1c-.8-.8-.978-.625-1.419-.467a8.073,8.073,0,0,0-1.3.69,3.919,3.919,0,0,0-1.56,1.647c-.31.669-.669,1.913,1.158,5.164a28.812,28.812,0,0,0,5.066,6.756h0l.005.005.005.005h0a28.925,28.925,0,0,0,6.756,5.066c3.25,1.826,4.495,1.468,5.164,1.158a3.852,3.852,0,0,0,1.647-1.56,8.073,8.073,0,0,0,.69-1.3C25.391,21.828,25.57,21.649,24.766,20.85Z" transform="translate(-4.49 -4.503)" fill="#fff"/>
          </svg>
          </a>
          <a v-if="isShowInstagram" :href="realEstate.socials[0].string === 'instagram' ? realEstate.socials[0].link : realEstate.socials[1].link " target="_blank" class="bongah-filter z-50 relative"><svg xmlns="http://www.w3.org/2000/svg" width="53.883" height="53.878" viewBox="0 0 53.883 53.878">
            <path  id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M11.939,8.055a6.122,6.122,0,1,0,6.122,6.122A6.112,6.112,0,0,0,11.939,8.055Zm0,10.1a3.98,3.98,0,1,1,3.98-3.98,3.987,3.987,0,0,1-3.98,3.98ZM19.739,7.8a1.428,1.428,0,1,1-1.428-1.428A1.425,1.425,0,0,1,19.739,7.8Zm4.055,1.449a7.066,7.066,0,0,0-1.929-5,7.113,7.113,0,0,0-5-1.929c-1.971-.112-7.88-.112-9.852,0a7.1,7.1,0,0,0-5,1.923,7.09,7.09,0,0,0-1.929,5c-.112,1.971-.112,7.88,0,9.852a7.066,7.066,0,0,0,1.929,5,7.122,7.122,0,0,0,5,1.929c1.971.112,7.88.112,9.852,0a7.066,7.066,0,0,0,5-1.929,7.113,7.113,0,0,0,1.929-5c.112-1.971.112-7.875,0-9.846ZM21.247,21.215a4.029,4.029,0,0,1-2.27,2.27c-1.572.623-5.3.48-7.038.48s-5.472.139-7.038-.48a4.029,4.029,0,0,1-2.27-2.27c-.623-1.572-.48-5.3-.48-7.038s-.139-5.472.48-7.038A4.029,4.029,0,0,1,4.9,4.868c1.572-.623,5.3-.48,7.038-.48s5.472-.139,7.038.48a4.029,4.029,0,0,1,2.27,2.27c.623,1.572.48,5.3.48,7.038S21.87,19.648,21.247,21.215Z" transform="translate(15.005 12.762)" fill="#fff"/>
          </svg>
          </a>
          <a v-if="isShowWhatsApp" :href="`https://wa.me/${realEstate.socials[0].string === 'whatsapp' ? realEstate.socials[0].link : realEstate.socials[1].link }`"  target="_blank" class="bongah-filter z-50 relative">
            <svg xmlns="http://www.w3.org/2000/svg" width="54.446" height="54.446" viewBox="0 0 54.446 54.446">
              <path id="Icon_simple-whatsapp" data-name="Icon simple-whatsapp" d="M17.823,14.649c-.307-.153-1.8-.883-2.078-.984s-.482-.153-.685.153S14.274,14.8,14.1,15s-.355.214-.658.076a8.267,8.267,0,0,1-2.448-1.513A9.241,9.241,0,0,1,9.3,11.457c-.177-.306-.019-.474.132-.626.139-.138.307-.351.459-.533a2.582,2.582,0,0,0,.3-.505.56.56,0,0,0-.025-.534c-.076-.153-.684-1.65-.939-2.247s-.5-.519-.684-.519c-.175-.015-.378-.015-.582-.015a1.148,1.148,0,0,0-.812.366A3.4,3.4,0,0,0,6.089,9.364,5.96,5.96,0,0,0,7.331,12.5a13.582,13.582,0,0,0,5.195,4.568c.727.306,1.294.489,1.736.641a4.214,4.214,0,0,0,1.915.123,3.135,3.135,0,0,0,2.053-1.452,2.521,2.521,0,0,0,.183-1.451c-.075-.138-.275-.214-.581-.351m-5.547,7.581h-.016A10.189,10.189,0,0,1,7.12,20.748l-.367-.218-3.82.993,1.024-3.713-.243-.382A10.1,10.1,0,0,1,19.447,4.967a9.97,9.97,0,0,1,2.963,7.12A10.115,10.115,0,0,1,12.291,22.155M20.9,3.513A12.367,12.367,0,0,0,12.269,0,12.11,12.11,0,0,0,1.727,18.169L0,24.446l6.453-1.683a12.286,12.286,0,0,0,5.816,1.474h.006A12.162,12.162,0,0,0,24.446,12.12a12.013,12.013,0,0,0-3.56-8.567" transform="translate(15 15)" fill="#fff"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <CustomerButton class="z-50"/>

  </div>
</template>

<script>
import FiltersMap from "../../components/estate/filtersMap";

import {mapir, mapMarker , mapNavigationControl, mapGeolocateControl} from "mapir-vue";
import CustomerButton from '../../components/navigationButton/CustomerButton'
import axiosMap from "../../plugins/mapAxios";
export default {
  name: "LocationCustomer",
  components: {
    mapir,
    mapMarker,
    CustomerButton,
    FiltersMap,
    mapNavigationControl,
    mapGeolocateControl
  },
  data() {
    return {
      isFiltersOpen : false,
      coordinates: [50.836656, 28.967274],
      markerCoordinates: [50.836656, 28.967274],
      nowCoordinates: null,
      token : this.$store.state.map.token,
      options : this.$store.state.map.options,
      isShowEstate : false,
      estate : null,
      zoom : 10,
      baseURL : this.$store.state.general.baseURL,
      notifCount : null,
      searchText : "",
      isShowRealEstate : false,
      realEstate : null,
      isShowInstagram : false,
      isShowWhatsApp : false
    }
  },
  computed :{
    filterData(){
      return this.$store.state.filters.estatesMap
    },
    filterRealestateData(){
      return this.$store.state.filters.realEstateMap
    },
    isFilter(){
      return this.$store.state.filters.isFilterEstate
    },
  },
  methods :{
    checkSocials(data){
      if (data && data !== undefined) {
        data.forEach(item => {
          item.string === 'instagram' ? this.isShowInstagram = true : this.isShowInstagram = false ;
          item.string === 'whatsapp' ? this.isShowWhatsApp = true : this.isShowWhatsApp = false
        })
      }
    },
    currentLocation(data){
      let crd = data.actualEvent.coords;
       axiosMap.get('/fast-reverse/',{
        params:{
          lat : crd.latitude,
          lon : crd.longitude
        }
      }).then( async ({data})=>{
         let city  = {
           name : data.county,
           lat : data.geom.coordinates[1],
           lng : data.geom.coordinates[0],
           province : {
             name : data.province
           }
         }

         await localStorage.setItem('city',JSON.stringify(city))
         await this.$store.commit('filters/CLEAR_ESTATES_MAP')
         await this.$store.commit('filters/CLEAR_REALESTATE_MAP')
         await this.$store.dispatch('filters/getDataWithFilterMap')
         await this.$store.dispatch('filters/getRealEstateFilter')
         this.searchText = city.name
      })
    },
    hideBox(){
      this.isShowEstate = false;
      this.isShowRealEstate  = false
    },
    async clearFilter(){
      this.$store.commit('filters/reset')
      await this.$store.commit('filters/CLEAR_ESTATES')
      await this.$store.dispatch('filters/getDataWithFilterMap')
    },
    handleCityFilter(){
      this.$router.push({name : 'SelectCityCustomer'})
    },
    handleShowEstate(data){
      this.estate = data
      this.isShowEstate = true;
    },
    handleShowRealEstate(data){
      this.realEstate = data;
      this.isShowInstagram = false;
      this.isShowWhatsApp = false;
      if (data.socials && data.socials !== undefined && data.socials !== null){
        this.checkSocials(data.socials)
      }
      this.isShowRealEstate = true;
    },
    findRealEstate(){
      this.$router.push({name : 'FindRealEstateCustomer'})
    }
  },
  mounted() {
    // get notifCount
    let user = JSON.parse(localStorage.getItem('user'))
    this.notifCount = user.notifications_count
    // reset data in map
    this.$store.commit('filters/CLEAR_ESTATES_MAP')
    this.$store.dispatch('filters/getDataWithFilterMap');
    // get and clear realEstates Data
    this.$store.commit('filters/CLEAR_REALESTATE_MAP')
    this.$store.dispatch('filters/getRealEstateFilter')
    let city = JSON.parse(localStorage.getItem('city'))
    city?.name ? this.searchText = city.name : this.searchText = 'شهر خود را انتخاب کنید'
    if ('city' in localStorage){
      let area = this.$store.state.map.area
      if (area){
        this.coordinates = [area.geom.coordinates[0] , area.geom.coordinates[1]]
        this.zoom = 16
      }else{
        this.coordinates = [city.lng ,city.latitude]
      }
    }

  },
  created() {
    let city = JSON.parse(localStorage.getItem('city'))
    this.coordinates = [city.lng ,city.lat]
  },
  destroyed() {
    this.$store.commit('map/CLEAR_AREA')
  }
}
</script>

<style >
#map{
  height: 100vh;
}
#searchMap{
  position: fixed !important;
  z-index: 99;
  left: 16px;
  top: 2rem;
  width: 90%;
  margin: 0;
}
#estate{
  position: absolute;
  left: 13px;
  bottom: 49%;
  width: calc(100% - 30px);
}


.bongah-filter >>> svg{
  margin: 12px 7px;
}
.mapboxgl-ctrl-bottom-right{
  bottom: 15% !important;
}
</style>